import FileUploadField from './file-upload-field';
import TableSearch from './table-search';
import UserGeneratedTableSearch from './user-generated-table-search';
import UserGeneratedContent from './user-generated-content';
import UserGeneratedTable from './user-generated-table';
import UserGeneratedZoomImage from './user-generated-zoom-image';
import AccordionController from './accordion-controller';


export default (di) => {
	di
		.setType({type: FileUploadField, name: 'FileUploadField', parent: 'PageComponent'})
		.setType({type: TableSearch, name: 'TableSearch', parent: 'PageComponent'})
		.setType({type: UserGeneratedTableSearch, name: 'UserGeneratedTableSearch', parent: 'PageComponent'})
		.setType({type: UserGeneratedContent, name: 'UserGeneratedContent', parent: 'PageComponent'})
		.setType({type: UserGeneratedTable, name: 'UserGeneratedTable', parent: 'PageComponent'})
		.setType({type: UserGeneratedZoomImage, name: 'UserGeneratedZoomImage', parent: 'PageComponent'})
		.setType({type: AccordionController, name: 'AccordionController', parent: 'PageComponent'})
	;
};
