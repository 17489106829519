import PageComponent from '../../common/component/page-component';

class Skiplinks extends PageComponent {

	constructor({
					root,
					element
				}) {
		super({root: root, element: element});
	}


	prepare() {
		this.isDe = document.documentElement.lang === 'de';
		this.autoplayDisabled = document.cookie.split(';').some((item) => item.includes('ubf-autoplay=false'));
		this.stopAutoplay = this.element.querySelector(this.dataSelector('stop-autoplay'));
		this.stopAutoplay.addEventListener('focus', () => {this.events.trigger(document.body, 'slideshow:stopAutoplay');});
		this.listeners.toggle = this.events.on(this.element, this.dataSelector('stop-autoplay'), 'click', this.onClick.bind(this));
		if (this.autoplayDisabled) {
			// allow slideshow to be loaded
			setTimeout(() => this.events.trigger(document.body, 'slideshow:stopAutoplay'), 500);
		}
		this.updateButton();
	}
	onFocus() {
		this.events.trigger(document.body, 'slideshow:stopAutoplay');
	}
	onClick() {
		if (this.autoplayDisabled) {
			document.cookie = 'ubf-autoplay=true';
			this.autoplayDisabled = false;
		} else {
			this.events.trigger(document.body, 'slideshow:stopAutoplay');
			document.cookie = 'ubf-autoplay=false';
			this.autoplayDisabled = true;
		}
		this.updateButton();
	}
	updateButton() {
		if (this.autoplayDisabled) {
			this.stopAutoplay.innerHTML = this.isDe ? 'Automatische Inhaltswechsel erlauben' : 'allow automatic content change';
			this.stopAutoplay.ariaDescription = this.isDe ? 'Erlaubt den automatischen Wechsel von Inhalten, z.B. bei Slideshows. Einstellung wird gespeichert' : 'Allows the automatic change of content, e.g. in slideshows. Setting is saved';
			this.stopAutoplay.ariaChecked = true;
		} else {
			this.stopAutoplay.innerHTML = this.isDe ? 'Automatische Inhaltswechsel vermeiden': 'avoid automatic content change';
			this.stopAutoplay.ariaDescription = this.isDe ? 'Verhindert den automatischen Wechsel von Inhalten, z.B. bei Slideshows. Einstellung wird gespeichert' : 'Prevents the automatic change of content, e.g. in slideshows. Setting is saved';
			this.stopAutoplay.ariaChecked = false;
		}
	}
}

export default Skiplinks;
