import SlideAnimator from '../../common/slideshow/slide-animator';


class SwapClassSlideAnimator extends SlideAnimator {

	constructor({currentClass = 'current'} = {}) {
		super();
		this.currentClass = currentClass;
	}


	setup(slideshow) {
		const currentSlide = slideshow.getCurrentSlide();
		// this should be already done in the markup, this is just to cover corner cases
		if (currentSlide) {
			this.classList(currentSlide.getElement()).add(this.currentClass);
		}
	}

	switch(currentSlide, newSlide, direction) {
		const newSlideElement = newSlide.getElement();
		if (currentSlide) {
			this.classList(currentSlide.getElement()).remove(this.currentClass);
			currentSlide.getElement().setAttribute('aria-hidden', 'true');
		}
		this.classList(newSlideElement).add(this.currentClass);
		newSlideElement.setAttribute('aria-hidden', 'false');

		return this.onTransitionEnd(newSlideElement).then(() => {
				newSlideElement.focus({preventScroll: true});
			});
	}
}


export default SwapClassSlideAnimator;
