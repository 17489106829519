import {gsap} from 'gsap';
import {Draggable} from 'gsap/dist/Draggable';
import ContentSlider from './content-slider';

gsap.registerPlugin(Draggable);


class ContentSliderGsap extends ContentSlider {

	constructor({
		root,
		element,
		snap = true,
		enabled = true,
		stepSize = '50%', // without % imply pixels
		directions = 'x,y',
		stepDuration = 0.4,
		emitProgress = false,
		preventLinkDrag = true,
		easing = 'Cubic.easeIn',
		stepReference = 'container', // container | content
		passCustomStepsCss = false,
		removeNegativeMargins = true,
		recalculateOnPicturesLoad = true,
		contentAttribute = 'movingContent',
		updateEvent = 'contentslider:update',
		fixedContentAttribute = 'fixedContent',
		cssVisibleItemAmountProperty = 'visibleItemAmount',
		movingContentItemsAttribute = 'movingContentItems',
	}) {
		super({root: root, element: element});
		this.defaults.snap = snap;
		this.defaults.easing = easing;
		this.defaults.enabled = enabled;
		this.defaults.stepSize = stepSize;
		this.defaults.directions = directions;
		this.defaults.updateEvent = updateEvent;
		this.defaults.emitProgress = emitProgress;
		this.defaults.stepReference = stepReference;
		this.defaults.preventLinkDrag = preventLinkDrag;
		this.defaults.contentAttribute = contentAttribute;
		this.defaults.passCustomStepsCss = passCustomStepsCss;
		this.fixedContentAttribute = fixedContentAttribute;
		this.defaults.removeNegativeMargins = removeNegativeMargins;
		this.defaults.recalculateOnPicturesLoad = recalculateOnPicturesLoad;
		this.movingContentItemsAttribute = movingContentItemsAttribute;
		this.stepDuration = stepDuration;
		this.cssVisibleItemAmountProperty = cssVisibleItemAmountProperty;

		this.id = '';
		this.draggable = null;
		this.enabled = null;
		this.enabledBySize = null;
		this.running = false;
		this.tween = null;
		this.tweening = false;
		this.currentStep = 0;
		this.stepAmount = 0;
		this.itemsSpacings = [];
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.updateEvent = data.updateEvent;
		this.enabled = data.enabled;
		this.clickRange = data.clickRange;
		this.snap = data.snap;
		this.stepReference = data.stepReference;
		this.easing = data.easing;
		this.passCustomStepsCss = data.passCustomStepsCss;
		this.emitProgress = data.emitProgress;
		const directions = data.directions.toLowerCase();
		this.id = data.id;

		this.directions = {x: directions.indexOf('x') >= 0, y: directions.indexOf('y') >= 0};

		this.content = this.element.querySelector(this.dataSelector(data.contentAttribute));
		this.fixedContent = this.element.querySelectorAll(this.dataSelector(this.fixedContentAttribute));

		if (data.preventLinkDrag) {
			this.listeners.dragLinks = this.events.on(this.element, 'a', 'dragstart', this.onLinkDragStart.bind(this), {capture: true});
		}

		this.listeners.mousedown = this.events.on(this.element, 'mousedown touchstart', this.onMouseDown.bind(this));

		this.draggable = Draggable.create(this.content, {
			type: directions,
			bounds: this.element,
			onDrag: this.onDrag.bind(this),
			onDragEnd: this.onDragEnd.bind(this)
		})[0];

		this.draggable.disable();
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));

		if (data.recalculateOnPicturesLoad) {
			this.listeners.picturesLoad = this.events.on(this.element, 'picture:load', this.onPictureLoad.bind(this));
		}
		this.removeNegativeMargins = data.removeNegativeMargins;

		if (this.stepReference === 'content') {
			this.getSpacings();
		}

		this.updateBounds();
		if (this.passCustomStepsCss) {
			this.updateStepAmount();
		}
	}


	start(first) {
		this.updateRunningStatus();
	}


	stop() {
		if (this.running) {
			this.running = false;
			this.draggable.disable();
		}
	}


	onLinkDragStart(event) {
		event.preventDefault();
	}


	onDrag() {
		if (this.emitProgress) {
			this.updateProgress();
		}
	}


	onDragEnd() {
		if (this.snap) {
			this.snapTo();
		}
	}


	onResize(event) {
		this.getSpacings();
		this.updateBounds();
		if (this.passCustomStepsCss) {
			this.updateStepAmount();
			if (this.currentStep > this.stepAmount) {
				this.currentStep = this.stepAmount;
			}
		}

		if (this.snap) {
			this.snapTo(this.currentStep);
		}
	}


	onPictureLoad(event) {
		this.getSpacings();
		this.updateBounds();
	}


	onMouseDown(event) {
		// this.mouseDownCoordinates = {x: event.screenX, y: event.screenY};
		if (this.tweening) {
			this.tween.kill();
			this.tween = null;
			this.tweening = false;
			if (this.resolveStep) {
				this.resolveStep();
			}
		}
	}


	enable() {
		if (!this.enabled) {
			this.enabled = true;
			this.updateRunningStatus();
		}
	}


	disable() {
		if (this.enabled) {
			this.enabled = false;
			this.updateRunningStatus();
		}
	}


	updateBounds() {
		const containerWidth = this.element.offsetWidth;
		const containerHeight = this.element.offsetHeight;

		let contentWidth = 0;
		let contentHeight = 0;

		for (const item of this.itemsSpacings) {
			contentWidth = contentWidth + item.spacings[0];
			contentHeight = contentHeight + item.spacings[1];
		}

		if (this.removeNegativeMargins) {
			const style = getComputedStyle(this.content);
			const topMargin = parseFloat(style.marginTop);
			if (!isNaN(topMargin) && topMargin < 0) {
				contentHeight += topMargin;
			}
			const leftMargin = parseFloat(style.marginLeft);
			if (!isNaN(leftMargin) && leftMargin < 0) {
				contentWidth += leftMargin;
			}
		}
		let enabledBySize = false;
		const diffX = contentWidth - containerWidth;
		const diffY = contentHeight - containerHeight;

		if (this.directions.x && diffX > 0 || this.directions.y && diffY > 0)  {
			enabledBySize = true;
		}
		this.enabledBySize = enabledBySize;
		if (enabledBySize) {
			const bounds = {
				left: -diffX,
				top: -diffY,
				width: contentWidth,
				height: contentHeight
			};

			this.boundX = [bounds.left, 0];
			this.boundY = [bounds.top, 0];
			this.draggable.vars.bounds = bounds;
		}
		this.draggable.update();
		this.updateRunningStatus();
	}


	stepTo(dir) {
		this.disable();
		return new Promise((resolve) => {
			this.resolveStep = resolve;
			const coordinate = dir === 'left' || dir === 'right' ? 'x' : 'y';
			const offset = dir === 'left' || dir === 'bottom' ? 1 : -1;
			const stepSize = this.dataAttr().get('stepSize');
			const isPercent = stepSize.indexOf('%') >= 0;
			let value;
			if (isPercent) {
				const ratio = parseFloat(stepSize) / 100;
				const node = this.stepReference === 'container' ? this.element : this.content;

				if (this.stepReference === 'container') {
					const prefix = 'offset';
					const suffix = coordinate === 'x' ? 'Width' : 'Height';
					const property = prefix + suffix;
					value = node[property] * ratio;
				} else {
					this.updateCurrentStep(dir);
					const spacings = this.itemsSpacings[this.currentStep].spacings;
					value = coordinate === 'x' ? spacings[0] : spacings[1];
				}
			} else {
				value = parseInt(stepSize, 10);
			}

			const limits = coordinate === 'x' ? this.boundX : this.boundY;
			const step = value * offset;
			const newValue = Math.max(limits[0], Math.min(limits[1], this[coordinate] + step));
			if (newValue !== this[coordinate]) {
				this.animateTo(coordinate, newValue).then(() => {
					this.enable();
					resolve();
				});
			} else {
				this.enable();
				resolve();
			}
		});
	}

	fixContent(x) {
		for (let i = 0; i < this.fixedContent.length; i++) {
			const node = this.fixedContent[i];
			node.style.transform = 'translateX(' + -x +'px)';
		}
	}


	snapTo(position) {
		let newValue = 0;
		const coordinate = this.directions.x ? 'x' : 'y';
		const dirIndex = coordinate === 'x' ? 0 : 1;
		if (!position) {
			newValue = this.getClosest();
		} else {
			newValue = -this.itemsSpacings[position].position[dirIndex];
		}
		this.animateFromCurrentTo(newValue);

	}


	animateTo(coordinate, newValue) {
		return new Promise((resolve) => {
			const dummy = {value: this[coordinate]};
			this.tweening = true;
			this.tween = gsap.to(dummy, {
				duration: this.stepDuration,
				value: newValue,
				onUpdate: () => {
					const params = coordinate === 'x' ? [dummy.value, this.y] : [this.x, dummy.value];
					this.updatePosition(...params, false, true);
				},
				onComplete: () => {
					this.tweening = false;
					resolve();
				}
			});
		});
	}


	animateFromCurrentTo(animatePosition) {
		const coordinate = this.directions.x ? 'x' : 'y';
		const dummy = {value: this[coordinate]};
		this.tweening = true;
		this.tween = gsap.fromTo(dummy, {
			value: coordinate === 'x' ? this.draggable.x : this.draggable.y,
		}, {
			value: animatePosition,
			duration: this.stepDuration,
			ease: this.easing,
			onUpdate: () => {
				const params = coordinate === 'x' ? [dummy.value, this.y] : [this.x, dummy.value];
				this.updatePosition(...params, false, true);
			},
			onComplete: () => {
				this.tweening = false;
			}
		});
	}


	getClosest() {
		const coordinate = this.directions.x ? 'x' : 'y';
		let currentPosition = coordinate === 'x' ? this.draggable.x : this.draggable.y;
		const dirIndex = coordinate === 'x' ? 0 : 1;
		let animatePosition = 0;

		for (let i = 0; i < this.itemsSpacings.length; i++) {
			currentPosition = currentPosition < 0 ? -currentPosition : currentPosition;
			const triggerPos = this.itemsSpacings[i].spacings[dirIndex] / 2 + this.itemsSpacings[i].position[dirIndex];
			const diff = triggerPos - currentPosition > 0 ? triggerPos - currentPosition : 0;
			if (diff > 0) {

				const bound = coordinate === 'x' ? this.boundX[0] : this.boundY[0];
				if (currentPosition < -bound && currentPosition !== -bound  && this.itemsSpacings[i].position[dirIndex] < -bound) {
					animatePosition = -this.itemsSpacings[i].position[dirIndex];
				} else {
					animatePosition = bound;
				}
				this.currentStep  = i;
				return animatePosition;
			}
		}

		// TODO: make work with y direction
		// for (let i = 0; i < this.itemsSpacings.length; i++) {
		// 	const isPositive = currentPosition > 0;
		// 	const spacing = this.itemsSpacings[i].spacings[dirIndex];
		// 	const position = this.itemsSpacings[i].position[dirIndex];
		// 	const triggerPos =  isPositive ? spacing / 2 + position : spacing / 2 - position;
		// 	const diff = triggerPos - currentPosition > 0 ? triggerPos - currentPosition : 0;

		// 	if (diff > 0) {
		// 		const bound = coordinate === 'x' ? this.boundX[0] : this.boundY[0];
		// 		if (currentPosition < -bound && currentPosition !== -bound  && this.itemsSpacings[i].position[dirIndex] < -bound) {
		// 			animatePosition = -this.itemsSpacings[i].position[dirIndex];
		// 			return animatePosition;
		// 		} else {
		// 			animatePosition = -bound;
		// 			return animatePosition;
		// 		}
		// 	}
		// }


		return animatePosition;
	}


	getSpacings() {
		this.itemsSpacings = [];
		let children =  this.content.querySelectorAll(this.dataSelector(this.movingContentItemsAttribute));
		if (children.length === 0) {
			// if there are no marked elements, broaden the search by selecting every td that is not fixed
			children =  this.content.querySelectorAll('td:not(' + this.dataSelector(this.fixedContentAttribute) + ')');
		}

		// get the spacing and margin and save as coordinates
		for (const child of children) {
			const style = child.currentStyle || window.getComputedStyle(child);

			const leftMargin = parseFloat(style.marginLeft);
			const rightMargin = parseFloat(style.marginRight);
			const topMargin = parseFloat(style.marginTop);
			const bottomMargin = parseFloat(style.marginBottom);

			const marginX = (!isNaN(leftMargin) ? leftMargin : 0) + (!isNaN(rightMargin) ? rightMargin : 0);
			const marginY = (!isNaN(topMargin) ? topMargin : 0) + (!isNaN(bottomMargin) ? bottomMargin : 0);
			const rect = child.getBoundingClientRect();

			const contentPosLeft = this.content.getBoundingClientRect().left;
			const contentPosBottom = this.content.getBoundingClientRect().bottom;
			const itemStartPosX = rect.left - contentPosLeft;
			const itemStartPosY = rect.bottom - contentPosBottom;

			this.itemsSpacings.push({
				spacings: [rect.width + marginX, rect.height + marginY],
				position: [itemStartPosX, itemStartPosY]
			});
		}

	}



	updateCurrentStep(dir) {
		if (dir === 'left' && this.currentStep > 0 || dir === 'top' && this.currentStep > 0) {
			this.currentStep--;
		}
		if (dir === 'right' && this.currentStep < this.stepAmount || dir === 'bottom' && this.currentStep < this.stepAmount) {
			this.currentStep++;
		}
	}


	updateStepAmount() {
		const itemAmount = this.itemsSpacings.length;

		if (this.passCustomStepsCss) {
			const visibleItemsAmount = this.cssData().get(this.cssVisibleItemAmountProperty);
			this.stepAmount = itemAmount - visibleItemsAmount;
		} else {
			this.stepAmount =  itemAmount - 1;
		}
	}


	updateRunningStatus() {
		if (this.enabled && this.enabledBySize) {
			if (!this.running) {
				this.draggable.enable();
				this.running = true;
			}
		} else if (this.running) {
			this.draggable.disable();
			this.running = false;
		}
		this.triggerUpdateEvent();
	}


	getStatus() {
		const status = {
			enabled: this.enabled,
			enabledBySize: this.enabledBySize,
			running: this.running,
			directions: this.directions
		};
		if (this.directions.x) {
			status.x = this.x;
			status.boundX = this.boundX;
		}
		if (this.directions.y) {
			status.y = this.y;
			status.boundY = this.boundY;
		}
		return status;
	}

	updatePosition(x, y, checkBounds = false, updateDraggable = false) {
		if (this.directions.x) {
			this.x = checkBounds ? Math.max(this.boundX[0], Math.min(this.boundX[1], x)) : x;
		}
		if (this.directions.y) {
			this.y = checkBounds ? Math.max(this.boundY[0], Math.min(this.boundY[1], y)) : y;
		}

		// gsap caching transfrom values on node, if transform manual set than draggable dont know the updated value
		// use gsap to make the transform
		gsap.set(this.content, {x: this.x, y: this.y});

		if (this.fixedContent) {
			this.fixContent(this.x);
		}

		if (updateDraggable) {
			this.draggable.update();
		}

		if (this.emitProgress) {
			const position = this.directions.x ? this.x : this.y;
			this.updateProgress(position);
		}
		this.triggerUpdateEvent();
	}


	triggerUpdateEvent() {
		this.events.trigger(this.element, this.updateEvent, this.getStatus());
	}


	updateProgress(position) {
		// to let in indicator know about its position
		if (!position) {
			position = this.directions.x ? this.draggable.x : this.draggable.y;
		}

		if (this.fixedContent) {
			this.fixContent(position);
		}

		const bound = this.directions.x ? this.boundX[0] : this.boundY[0];
		const progress =  (100 / bound * position) / 100;
		this.events.trigger(document, 'indicator:change', {
			animate: position === true,
			id: this.id,
			progress: progress,
			currentStep: this.currentStep
		});
	}
}


export default ContentSliderGsap;
