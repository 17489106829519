import {getScrollTop} from '../../common/utils/get-scroll';
import PageComponent from '../../common/component/page-component';


class MainMenu extends PageComponent {

	constructor({
		root,
		element,
		alternativeClass = 'alternative',
		menuItemAttribute = 'menuItem',
		subMenuAttribute = 'subMenu',
		subMenuTogglerAttribute = 'subMenuToggler',
		subMenuLinkAttribute = 'subMenuLink',
		mainAttribute = 'main',
		additionalPadding = -80,
		cssEnabledProperty = 'enableScrollMonitor',
		focusWithinClass = 'focusWithin'
	}) {
		super({root: root, element: element});

		this.alternativeClass = alternativeClass;
		this.menuItemAttribute = menuItemAttribute;
		this.subMenuAttribute = subMenuAttribute;
		this.subMenuTogglerAttribute = subMenuTogglerAttribute;
		this.subMenuLinkAttribute = subMenuLinkAttribute;
		this.mainAttribute = mainAttribute;
		this.additionalPadding = additionalPadding;
		this.cssEnabledProperty = cssEnabledProperty;
		this.isScrollEnabled = false;
		this.maxSubMenusHeight = 0;
		this.availableSpace = 0; // initial position of the menu
		this.focusWithinClass = focusWithinClass;
	}


	prepare() {
		this.main = this.root.querySelector(this.dataSelector(this.mainAttribute));
		this.subMenus = this.element.querySelectorAll(this.dataSelector(this.subMenuAttribute));

		this.listeners.togglerOver = this.events.on(this.element, this.dataSelector(this.subMenuTogglerAttribute), 'mouseover', this.onTogglerOver.bind(this));

		this.listeners.focus = this.events.on(this.element, this.dataSelector(this.menuItemAttribute), 'focus focusin', this.onFocus.bind(this));
		this.listeners.blur = this.events.on(this.element, this.dataSelector(this.menuItemAttribute), 'blur focusout', this.onBlur.bind(this));

		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.updateSize();
	}


	onResize(event, target) {
		this.updateSize();
	}


	onScroll(event, target) {
		this.updatePosition();
	}

	onTogglerOver(event, target) {
		if(document.activeElement.hasAttribute(this.dataAttrParser.getAttributeName(this.subMenuTogglerAttribute)) ||
				document.activeElement.hasAttribute(this.dataAttrParser.getAttributeName(this.subMenuLinkAttribute))) {
				document.activeElement.blur();
		}
	}

	onFocus(event, target) {
		this.classList(target).add(this.focusWithinClass);
	}

	onBlur(event, target) {
		this.classList(target).remove(this.focusWithinClass);
	}

	updateSize() {
		if (this.cssData().get(this.cssEnabledProperty)) {
			const mainRect = this.main.getBoundingClientRect();
			const rect = this.element.getBoundingClientRect();
			this.availableSpace = mainRect.top - rect.height - this.additionalPadding + getScrollTop();

			this.maxSubMenusHeight = 0;
			for (const subMenu of this.subMenus) {
				const availableHeight = window.innerHeight - 80;

				const subMenuHeight = subMenu.children.length * 40 + 60; // subMenu.getBoundingClientRect().height;
				this.maxSubMenusHeight = Math.max(this.maxSubMenusHeight, subMenuHeight);

				if (window.innerHeight < 800) {
					const neededCols = Math.min(4, Math.ceil(subMenuHeight / availableHeight));
					const contentWidth = 270 * neededCols;
					subMenu.style.columnCount = neededCols;
					subMenu.style.width = (contentWidth + 15) + 'px';
				} else {
					subMenu.style.width = 'initial';
				}
			}

			this.enableScrollMonitor();
			this.updatePosition();
		} else {
			this.disableScrollMonitor();
			this.classList(this.element).remove(this.alternativeClass);
		}
	}


	enableScrollMonitor() {
		if (!this.isScrollEnabled) {
			this.isScrollEnabled = true;
			this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
		}
	}


	disableScrollMonitor() {
		if (this.isScrollEnabled) {
			this.isScrollEnabled = false;
			this.listeners.scroll.destroy();
			delete this.listeners.scroll;
		}
	}


	updatePosition() {
		const isEnoughTopSpace = this.availableSpace - getScrollTop() >= this.maxSubMenusHeight;
		this.classList(this.element).toggle(this.alternativeClass, isEnoughTopSpace);
	}
}


export default MainMenu;
