import PageComponent from '../component/page-component';


class ExternalVideo extends PageComponent {

	constructor({
		element,
		root,
		linkAttribute = 'externalVideoLink',
		playerAttribute = 'externalVideoPlayer',
		visiblePlayerClass = 'visiblePlayer'
	} = {}) {
		super({element: element, root: root});
		this.defaults.linkAttribute = linkAttribute;
		this.defaults.playerAttribute = playerAttribute;
		this.defaults.visiblePlayerClass = visiblePlayerClass;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.link = this.element.querySelector(this.dataSelector(data.linkAttribute));
		this.source = this.element.querySelector(this.dataSelector(data.playerAttribute));
		if (!this.source) {
			return;
		}
		this.textContent = this.source.textContent.replace('youtube.com', 'youtube-nocookie.com');
		if (this.link && this.textContent) {
			this.listeners.click = this.events.on(this.element, this.dataSelector(data.linkAttribute), 'click', this.onClick.bind(this));
			this.playerAttribute = data.playerAttribute;
			this.visiblePlayerClass = data.visiblePlayerClass;
			this.wrapper = this.link.parentNode;
			this.originalTextContent = this.wrapper.innerHTML;
		}
	}


	onClick(event) {
		if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
			event.preventDefault();
			if (this.listeners.click) this.listeners.click.destroy();
			delete this.listeners.click;
			this.wrapper.innerHTML = this.textContent;
			this.classList(this.element).add(this.visiblePlayerClass);
		}
	}
	remove() {
		this.wrapper.innerHTML = this.originalTextContent;
		this.listeners.click = this.events.on(this.element, this.dataSelector('externalVideoLink'), 'click', this.onClick.bind(this));
		this.classList(this.element).remove(this.visiblePlayerClass);
	}

}


export default ExternalVideo;
