import PageComponent from '../../common/component/page-component';


// the purpose of this component is to replicate the table headers inside each cell,
// in order to show a label > value structure on small screens where the table layout is switching to a linear block layout
class UserGeneratedTableSearch extends PageComponent {

	constructor({
		root,
		element,
		sortable = false,
		searchable = false,
		hiddenModifier = 'hidden',
		tableRowAttribute = 'tableRow',
		ascendingModifier = 'ascending',
		descendingModifier = 'descending',
		highlightModifier = 'highlighted',
		sortButtonAttribute = 'sortButton',
		searchInputAttribute = 'searchInput',
		tableHeaderAttribute = 'tableHeader',
		sortDirectionAttribute = 'sortDirection',
		cellSearchValueAttribute = 'cellSearchValue',
	}) {
		super({root: root, element: element});
		this.defaults.sortable = sortable;
		this.hiddenModifier = hiddenModifier;
		this.defaults.searchable = searchable;
		this.tableRowAttribute = tableRowAttribute;
		this.highlightModifier = highlightModifier;
		this.ascendingModifier = ascendingModifier;
		this.descendingModifier = descendingModifier;
		this.sortButtonAttribute = sortButtonAttribute;
		this.tableHeaderAttribute = tableHeaderAttribute;
		this.searchInputAttribute = searchInputAttribute;
		this.sortDirectionAttribute = sortDirectionAttribute;
		this.cellSearchValueAttribute = cellSearchValueAttribute;
	}

	prepare() {
		const data = this.dataAttr().getAll();
		const searchable = data.searchable;
		const sortable = data.sortable;
		const elementClass = this.classList(this.element);

		this.tableRowNodes = this.element.querySelectorAll(this.dataSelector(this.tableRowAttribute));
		this.tableHeaderNode = this.element.querySelector(this.dataSelector(this.tableHeaderAttribute));

		if (searchable) {
			this.searchInputNode = this.element.querySelector(this.dataSelector(this.searchInputAttribute));
			elementClass.add('searchable');

			if (this.searchInputNode) {
				this.listeners.change = this.events.on(this.searchInputNode, 'input', this.onChange.bind(this));
				this.listeners.key = this.events.on(this.searchInputNode, 'keypress', this.onKeypress.bind(this));
			}
		}

		if (sortable && this.tableHeaderNode) {
			elementClass.add('sortable');

			this.listeners.sortClick = this.events.on(this.tableHeaderNode, this.dataSelector(this.sortButtonAttribute), 'click', this.onSortClick.bind(this));
		}
	}

	onChange(event) {
		const input = event.target.value;
		const noPunctationInput = input.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');
		const regex = new RegExp(noPunctationInput.toLowerCase(), 'g');

		for (const row of this.tableRowNodes) {
			const cellTextNodes = row.querySelectorAll('td');

			for (const cellTextNode of cellTextNodes) {
				const cellClass = this.classList(cellTextNode);
				const textValue = cellTextNode.innerText || cellTextNode.textContent;

				const escapedTextValue = textValue.toString();
				const lowercaseTextValue = escapedTextValue.toLowerCase();
				const noPunctationValue = lowercaseTextValue.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');

				const found = noPunctationValue.match(regex);

				cellClass.add(this.hiddenModifier);
				cellClass.remove(this.highlightModifier);
				cellTextNode.setAttribute('aria-hidden', true);
				cellTextNode.setAttribute('tab-index', '-1');

				if (found) {
					cellTextNode.setAttribute('aria-hidden', false);
					cellTextNode.removeAttribute('tab-index', '-1');
					cellClass.remove(this.hiddenModifier);
				}

				if (found && noPunctationInput.length > 0) {
					cellClass.add(this.highlightModifier);
				}
			}
		}
	}

	onKeypress(event) {
		const code = event.keyCode || event.which;
		if (code === 13) {
			event.preventDefault();
			return false;
		}
		return false;
	}


	onSortClick(event, target) {
		const buttons = this.tableHeaderNode.querySelectorAll(this.dataSelector(this.sortButtonAttribute));
		let sortColumn = 0;

		for (let i = 0; i < buttons.length; i++) {
			const button = buttons[i];
			if (button.nodeName !== '#text') {
				const buttonClass = this.classList(button);

				if (button === target) {
					const hasAscending = buttonClass.contains(this.ascendingModifier);
					const hasDescending = buttonClass.contains(this.descendingModifier);

					if (!hasAscending && !hasDescending) {
						buttonClass.add(this.ascendingModifier);
					} else {
						if (hasAscending) {
							buttonClass.remove(this.ascendingModifier);
							buttonClass.add(this.descendingModifier);
						} else {
							buttonClass.remove(this.descendingModifier);
							buttonClass.add(this.ascendingModifier);
						}
					}
					sortColumn = i;
				} else {
					buttonClass.remove(this.ascendingModifier);
					buttonClass.remove(this.descendingModifier);

				}
			}

		}

		const container = this.tableHeaderNode.parentElement;
		const rows = [];
		for(const row of container.childNodes) {
			if (row !== this.tableHeaderNode) {
				let columnIndexA = -1;
				for (const column of row.childNodes) {
					// ignore th and non text nodes
					if (column.nodeName !== '#text' && column.nodeName !== 'TH') {
						columnIndexA++;
						if (columnIndexA === sortColumn) {
							rows.push({key: this.getText(column), row: row});
							break;
						}
					}
				}
			}
		}
		const sortDirectionAscending = this.classList(target).contains(this.ascendingModifier);
		rows.sort((a, b) => {
			let result = 0;
			const searchKeyA = a.key.toLowerCase();
			const searchKeyB = b.key.toLowerCase();

			if (searchKeyA < searchKeyB) {
				result = sortDirectionAscending ? -1 : 1;
			} else if (searchKeyA > searchKeyB) {
				result = sortDirectionAscending ? 1 : -1;
			}
			return result;
		});


		const unsortedRows = container.querySelectorAll(this.dataSelector(this.tableRowAttribute));

		for (let i = 0; i < unsortedRows.length; i++) {
			const unsortedRow = unsortedRows[i];
			container.removeChild(unsortedRow);
		}

		for (const row of rows) {
			container.appendChild(row.row); // your boat, gently down the stream
		}
	}


	getText(element) {
		let result = '';
		for (const child of element.childNodes) {
			if (child.nodeName === '#text') {
				result = result + ' ' + child.nodeValue.trim();
			} else {
				// recurse into child nodes that are not ubfNestedHeader
				if (child.dataset.ubfNestedHeader === undefined) {
					result =  result + ' ' + this.getText(child);
				}
			}
		}
		return result.trim();
	}
}


export default UserGeneratedTableSearch;
