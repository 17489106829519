// adapted from https://github.com/calvellido/focus-options-polyfill/blob/master/index.js
/*eslint-disable smells/no-this-assign, prefer-rest-params*/
//focus - focusOptions - preventScroll polyfill

let supportsPreventScrollOption = false;
try {
	const focusElem = document.createElement('div');
		focusElem.addEventListener('focus', (event) => {
			event.preventDefault();
			event.stopPropagation();
		},
		true
	);
	// document.documentElement.focus(
	focusElem.focus(
		Object.defineProperty({}, 'preventScroll', {get: function () {
			supportsPreventScrollOption = true;
		}})
	);
} catch(e) {
	//
}

if (HTMLElement.prototype.nativeFocus === undefined && !supportsPreventScrollOption) {
	HTMLElement.prototype.nativeFocus = HTMLElement.prototype.focus;

	const patchedFocus = function (args) {
		const actualPosition = window.scrollY;
		this.nativeFocus();
		window.scroll(window.scrollX, actualPosition);
		if (args && args.preventScroll) {
			// Hijacking the event loop order, since the focus() will trigger
			// internally an scroll that goes to the event loop
			setTimeout(() => {
				window.scroll(window.scrollX, actualPosition);
			}, 0);
		}
	};

	HTMLElement.prototype.focus = patchedFocus;
}
