import ExternalVideo from './external-video';
import AsyncPicture from './async-picture';



const config = (di) => {
	di
		.setType({type: ExternalVideo, name: 'ExternalVideo', parent: 'PageComponent'})
		.setType({type: AsyncPicture, name: 'AsyncPicture', parent: 'PageComponent'})
		;
};


export default config;
