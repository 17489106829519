import 'dayjs/locale/de';

import commonConfig from './common';

import animationConfig from '../animation/_config';
import appConfig from '../app/_config';
import contentConfig from '../content/_config';
import contextConfig from '../context/_config';
import galleryConfig from '../gallery/_config';
import menuConfig from '../menu/_config';
import sectionConfig from '../section/_config';
import templateBuilderConfig from '../template-builder/_config';
import sectionNaviConfig from '../sectionnavi/_config';
import skipLinksConfig from '../skiplinks/_config';
import test from '../test/_config';
import jobsTeaserConfig from '../jobs-teaser/_config';
import youtubeShort from '../youtube-short/_config';
import studyForm from '../studyform/_config';
import previewsSliderPaging from '../previewsSliderPaging/_config';
import revealMore from '../revealMore/_config';


const config = (di) => {
	commonConfig(di);

	animationConfig(di);
	appConfig(di);
	contentConfig(di);
	contextConfig(di);
	galleryConfig(di);
	menuConfig(di);
	sectionConfig(di);
	sectionNaviConfig(di);
	templateBuilderConfig(di);
	skipLinksConfig(di);
	test(di);
	jobsTeaserConfig(di);
	youtubeShort(di);
	studyForm(di);
	previewsSliderPaging(di);
	revealMore(di);

	const prefix = 'ubf-';
	const classPrefix = 'ubf-js-';

	di
		.setValue('dom/dataAttrPrefix', prefix)
		.setValue('dom/classPrefix', classPrefix)
		.setValue('dom/baseClassPrefix', prefix)
	;

	const bodyData = di.get('dom/dataAttrFactory')({element: document.body});
	const baseUrl = bodyData.get('baseUrl');

	di
		.setValue('baseUrl', baseUrl)
		.setParam('HashScroller', 'duration', 0.5)
	;
};


export default config;
