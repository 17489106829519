import {getScrollTop} from './../../common/utils/get-scroll';
import PageComponent from '../../common/component/page-component';

class ShowOnScroll extends PageComponent {
	constructor({
		root,
		element,
		visibilityTreshhold = '20',
		visibileModifier = 'visible'

	}) {
		super({root: root, element: element});
		this.element = element;
		this.visibileModifier = visibileModifier;
		this.defaults.visibilityTreshhold = visibilityTreshhold;
	}

	prepare() {
		this.visibilityTreshhold = this.dataAttr().get('visibilityTreshhold');
		this.getHeight();
		this.scrollAction();
		this.setListeners();
	}


	setListeners() {
		this.listeners.scroll = this.events.on(window, 'window:scroll window:resize', this.onEvent.bind(this));
	}

	onEvent(event) {
		this.getHeight();
		this.scrollAction();
	}

	scrollAction() {
		const scrollTop = getScrollTop();
		const relativeScrollTop = 100 / this.viewportHeight * scrollTop;
		if (relativeScrollTop > this.visibilityTreshhold) {
			this.classList(this.element).add(this.visibileModifier);
		} else {
			this.classList(this.element).remove(this.visibileModifier);
		}
	}

	getHeight() {
		this.viewportHeight = Math.max(document.body.clientHeight, window.innerHeight || 0);
	}
}

export default ShowOnScroll;
