import MainMenu from './main-menu';
import SecondaryMenu from './secondary-menu';
import Header from './header';
import SearchToggler from './search-toggler';
import DynamicCollapsable from './dynamicCollapsable';


export default (di) => {
	di
		.setType({type: MainMenu, name: 'MainMenu', parent: 'PageComponent'})
		.setType({type: SecondaryMenu, name: 'SecondaryMenu', parent: 'PageComponent'})
		.setType({type: Header, name: 'Header', parent: 'PageComponent'})
		.setType({type: SearchToggler, name: 'SearchToggler', parent: 'PageComponent'})
		.setType({type: DynamicCollapsable, name: 'DynamicCollapsable', parent: 'Collapsable'})
	;
};
