import 'intersection-observer';
import svg4everybody from 'svg4everybody';
import picturefill from 'picturefill';
import App from '../../common/app/app';

class UbfApp extends App {

	injectCurrentLinkManager(currentLinkManager) {
		this.currentLinkManager = currentLinkManager;
	}

	injectNavigationTracker(tracker) {
		this.tracker = tracker;
	}


	injectHashScroller(hashScroller) {
		this.hashScroller = hashScroller;
	}

	injectPages(pages) {
		this.pages = pages;
	}

	init() {
		svg4everybody();
		picturefill();
		this.contexts.setDefaultContext('default', false);
		return super.init();
	}

	execute() {
		// const loader = this.getComponent('AppLoader');
		// loader.hide();

		const body = document.querySelector('body');
		this.togglers = body.querySelectorAll(this.dataSelector('component', 'Toggler'));
		this.tabs = body.querySelectorAll(this.dataSelector('component', 'Tabs'));
		this.events.on(body, this.dataSelector('link'), 'click', this.onClick.bind(this));

		// check the href in the init to make sure our components are instantiated allready
		this.onLoad();
		this.contexts.getContext('default').activate();
	}

	onClick(event, target) {
		const url = target.getAttribute('href');
		//if target is on the current page and has a hash, prevent Default and do hashScrolling
		if (url.indexOf('#' !== -1) && window.location.pathname === target.pathname) {
			event.preventDefault();
			//Because the toggler Hash in Roxen usually has the path in it (href="/test/#accordion-123456"), only use the hash
			const urlArray = url.split('#');
			const href = '#' + urlArray[1];
			//Check if it is an element inside of an accordion
			const hashElement = document.getElementById(urlArray[1]);
			//Get the Accordion element, returns null (falsy) if element is not inside of an accordion
			const accordionElementCheck = hashElement.closest('.ubf-accordion__content');

			//Accordions and tabs and Elements inside of Accordions need a page reload for hash Scrolling
			if (url.indexOf('#accordion') !== -1 || url.indexOf('#tab') !== -1 || accordionElementCheck) {
				location.hash = href;
				location.reload();
			} else {
				this.scrollToAnchor(href);
			}
		}
	}

	//Scroll to Anchor and Toggle Component
	onLoad() {
		//replace all ':' in the href with '_' section a-tags which are set by users in text and picture components
		//If this replacement could be done in Roxen ck editor and would be also valid for already existing links, this can get removed
		const sourceAnchors = document.querySelectorAll('section a:not([class])');
		sourceAnchors.forEach((sourceAnchor) => {
			let link = sourceAnchor.getAttribute('href');
			if (link) {
				if (link.indexOf('#') !== -1 && link.indexOf('comp:') !== -1) {
					link = link.replace(/:/gi, '_');
					link = link.replace('https_', 'https:');
					sourceAnchor.removeAttribute('href');
					sourceAnchor.setAttribute('href', link);
				}
			}
		});

		//Check if there are Accordions or Tabs and toggle/select them and scroll to them
		//Only prevent Default if there is a hash and the hash does not include a Colon 'comp:' since this creates an JS/CSS error
		//Because usually Roxen component ids include a colon (UUIDs) and start with 'comp:'
		if (window.location.href.indexOf('#') !== -1 && !window.location.href.indexOf('comp:') !== -1) {
			const res = window.location.href.split('#');
			let hash = '#' + res[1];
			hash = hash.replace(/:/gi, '_');
			hash = hash.replace('https_', 'https:');

			//Check if it is an element inside of an accordion
			const hashElement = document.getElementById(res[1]);
			let accordionElementCheck = null;
			if (hashElement) {
				//Get Corresponding Accordion-Element, null if there isn't any
				accordionElementCheck = hashElement.closest('.ubf-accordion__content');
			}

			//If it is an Accordion Component, toggle Accordion and Scroll to hash
			if (hash.indexOf('accordion') !== -1) {
				this.pairTogglers(hash).then(() => {
					this.scrollToAnchor(hash);
				});
			} else if (accordionElementCheck) { //If it is an Element inside of an accordion, toggle accordion and scroll to anchor
				const accordionElementHash = '#' + accordionElementCheck.getAttribute('data-ubf-id');
				this.pairTogglers(accordionElementHash).then(() => {
					setTimeout(() => {
						this.scrollToAnchor(hash);
					}, 300);
				});
			} else if (hash.indexOf('tab') !== -1) { //If it is an Tabs Component
				this.scrollToSelectedTab(hash);
				//If it is not a tab or an accordion, just scroll to it
			} else {
				this.scrollToAnchor(hash);
			}
		}

		//Set all Contact-Komponents to same height (if there are more than 2 Contact Components) on desktop
		// NOTE: it could be considered that this part has its own component since it belongs to a specific element and alters it
		if (window.innerWidth > 1023) {
			const contactComponents = document.querySelectorAll('.ubf-section__content >.ubf-contentBlock--address');
			if (contactComponents.length > 1) {
				// NOTE: please use only english var names
				const maxHeight = this.getHeight(contactComponents);
				this.setHeight(contactComponents, maxHeight);
			}
		}
		//Add Event Listener for Click on Language Switch -> set global language Cookie value ()
		if (document.querySelector('.ubf-mainMenu__item--lang')) {
			document.querySelector('.ubf-mainMenu__item--lang').addEventListener('click', (e) => {
				let targetLang=e.target.innerText.toLowerCase();
				this.synchronizeUbfLocaleCookie(targetLang);
			});
		}
	}

	getHeight(components) {
		let height = 0;
		components.forEach(component => {
			if (component.offsetHeight > height) {
				height = component.offsetHeight;
			}
		});
		return height;
	}

	setHeight(components, height) {
		components.forEach(component => {
			// NOTE: for conformity with our codebase please keep and whitespace distance between the parts
			component.style.height = height + 'px';
		});
	}

	//Set Tab to selected and HashScrolling to its Parent Tabs-Component
	scrollToSelectedTab(hash) {
		//The Roxen link of the component is "tab-123", but the linked component is "tabs-123"
		const href = hash.replace('tab-', 'tabs-');
		const selectedTab = document.querySelector('a.ubf-tabs__tabLink[href="' + href + '"');
		//get whole tabs component to the selected tab and the id of it to scroll to it
		if (selectedTab) {
			const selectedTabs = selectedTab.parentElement.parentElement;
			let selectedTabsId = selectedTabs.parentElement.querySelector('a').getAttribute('id');
			const selectedTabsComponent = this.getComponent(selectedTabs);
			//on mobile, a tab switches to an accordion
			if (window.innerWidth < 1024) {
				const anchor = href.replace('tabs-', 'tab-');
				this.pairTogglers(href);
				this.scrollToAnchor(anchor);
			} else {
				//select tab link element, set tab to selected
				selectedTabsComponent.selectByTab(selectedTab);
				//Scroll to tabs component id
				selectedTabsId = '#' + selectedTabsId;
				this.scrollToAnchor(selectedTabsId);
				//Replace window location with selected tab id again
				window.location.href = window.location.href.replace(selectedTabsId, hash);
			}
			return true;
		} else {
			return false;
		}

	}
	//For Accordion Components, search for the component from the hash and toggle Component
	pairTogglers(hash) {
		// search all element with the Toggler component and compare there hashes
		for (const toggler of this.togglers) {
			let togglerHash = toggler.getAttribute('href');
			//Because the toggler Hash in Roxen usually has the path in it (href="/test/#accordion-123456"), only use the hash
			const urlArray = togglerHash.split('#');
			togglerHash = '#' + urlArray[1];
			if (togglerHash === hash) {
				const TogglerComponent = this.getComponent(toggler);
				if (TogglerComponent) {
					// if there not allready toggled (which should in our case be equal to collapsed)
					// call the methode of the component to toggle and return the promise
					if (!this.classList(toggler).contains('toggled')) {
						return TogglerComponent.toggle();
					}
				}
			}
		}
		return Promise.resolve();
	}

	scrollToAnchor(hash) {
		// we are triggering on event that the hash had changed to trigger the src/js/common/navigation/hash-scroller.js
		this.events.trigger(document.body, 'history:hashchange', {hash: hash.substr(1)});
		if (history.pushState) {
			history.pushState(null, null, hash);
		} else {
			location.hash = hash;
		}
	}
	//Function for ubf_locale global language Cookie
	synchronizeUbfLocaleCookie(targetLang) {
		//set the CookieValue of the ubf_locale to new targetLang
		let d = new Date();
		d.setTime(d.getTime() + (90 * 24 * 60 * 60 * 1000));
		let expirydate = "expires=" + d.toUTCString();
		document.cookie = "ubf_locale" + "=" + targetLang + ";" + expirydate + ";path=/;domain=" + 'uni-bielefeld.de';
	}
}
export default UbfApp;
