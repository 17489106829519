import PageComponent from '../../common/component/page-component';

class PreviewsSliderPaging extends PageComponent {

	constructor({root, element}) {
		super({root: root, element: element});
	}


	prepare() {
		this.id = this.dataAttr().get('for', '');
		this.slider = this.components.queryComponent(this.root, this.dataSelector('id', this.id));
		this.listeners.move = this.events.on(this.slider.getElement(), 'slider:move', this.onMove.bind(this));
		this.updateItems();
		this.listeners.resize = this.events.on(window, 'resize', this.updateItems.bind(this));
	}

	updateItems() {
		if (this.amount !== this.slider.amount || this.currentIndex !== this.slider.getIndex() || this.slideSize !== this.slider.getSlideSize()) {
			this.element.innerHTML = '';
			this.amount = this.slider.amount;
			this.currentIndex = this.slider.getIndex();
			this.slideSize = this.slider.getSlideSize();
			if (this.amount > this.slideSize) {
				const listElement = this.stringToNode(this.createPagingMarkup()).firstChild;
				for (let i = 0; i < this.amount; i++) {
					const itemElement = this.stringToNode(this.createDotMarkup(this.id, i, this.isVisible(i, this.slideSize))).firstChild;
					itemElement.addEventListener('click', this.onClick.bind(this, i));
					listElement.appendChild(itemElement);
				}
				this.element.appendChild(listElement);
			}
		}
	}

	onClick(index) {
		this.slider.setIndex(Math.min(index, this.amount - this.slideSize));
		this.updateItems();
	}
	onMove() {
		this.updateItems();
	}

	isVisible(current, size) {
		return current >= this.currentIndex && current < this.currentIndex + size;
	}

	createPagingMarkup() {
		return '<ul class="ubf-previewsSliderPaging__items" role="tablist"></ul>';
	}

	createDotMarkup(id, index, visible) {
		const li = '<li class="ubf-previewsSliderPaging__item" role="presentation">' +
			'<button role="tab" id="' + (this.id + '-' + index) + '" class="ubf-previewsSliderPaging__link ' + (visible ? 'ubf-js-current' : '') + '">' +
			'<span class="ubf-previewsSliderPaging__icon"></span>' +
			'</button>' +
			'</li>';
		return li;
	}

	stringToNode(html) {
		const template = document.createElement('template');
		template.innerHTML = html;
		return template.content.cloneNode(true);
	}

}

export default PreviewsSliderPaging;
