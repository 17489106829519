import {getScrollTop} from '../../common/utils/get-scroll';
import {getViewportHeight} from '../../common/utils/size';
import PageComponent from '../../common/component/page-component';


class SecondaryMenu extends PageComponent {

	constructor({
		root,
		element,
		navAttribute = 'secondaryMenuNav'
	}) {
		super({root: root, element: element});
		this.navAttribute = navAttribute;
		this.isScrollEnabled = false;
		this.isSticky = false;
		this.previousScrollTop = 0;
		this.offset = 0;
	}

	prepare() {
		// not doing anything if the menu is not sticky (on IE)
		if (document.querySelector('html.csspositionsticky')) {
			this.nav = this.element.querySelector(this.dataSelector(this.navAttribute));

			this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
			this.listeners.contentResize = this.events.on(this.element, 'content:resize', this.onResize.bind(this));
			this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this));

			this.updateSize();
		}
	}


	onResize(event) {
		this.updateSize();
	}


	onScroll(event) {
		const scrollTop = getScrollTop();
		this.updatePosition(scrollTop);
	}


	updateSize() {
		if (getComputedStyle(this.element).display !== 'none') {
			const elementRect = this.element.getBoundingClientRect();
			const scrollTop = getScrollTop();
			this.stickyTopPosition = scrollTop + elementRect.top;
			this.viewportHeight = getViewportHeight();
			this.menuHeight = this.nav.getBoundingClientRect().height;
			this.menuTop = parseInt(getComputedStyle(this.nav).top, 10);
			this.updatePosition(scrollTop);
			this.enableScrollMonitor();
		} else {
			this.disableScrollMonitor();
		}
	}


	updatePosition(scrollTop) {
		const scrollDiff = scrollTop - this.previousScrollTop;
		this.previousScrollTop = scrollTop;
		const isSticky = scrollTop > this.stickyTopPosition;
		if (isSticky) {
			const diff = this.menuTop + this.menuHeight - this.viewportHeight;
			const isFitting = diff < 0;
			if (!isFitting) {
				this.offset += scrollDiff;
				this.offset = Math.max(0, Math.min(diff, this.offset));
				this.nav.style.transform = 'translateY(-' + this.offset + 'px)';
			} else {
				this.nav.style.transform = 'translateY(0)';
			}
		} else {
			if (this.isSticky) {
				this.nav.style.removeProperty('transform');
			}
		}
		this.isSticky = isSticky;
	}


	enableScrollMonitor() {
		if (!this.isScrollEnabled) {
			this.isScrollEnabled = true;
			this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
		}
	}


	disableScrollMonitor() {
		if (this.isScrollEnabled) {
			this.isScrollEnabled = false;
			this.listeners.scroll.destroy();
			delete this.listeners.scroll;
		}
	}
}

export default SecondaryMenu;
