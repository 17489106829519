import ContextToggler from './context-toggler';


class AlertToggler extends ContextToggler {

	constructor({
		root,
		element,
		eventType = 'click',
		toggledClass = 'toggled',
		preventDefault = true,
		blurOnToggle = true,
		contextAttribute = 'forContext',
		targetAction = 'toggle',
		beforeToggleEvent = 'contexttoggler:beforetoggle',
		toggleEvent = 'contexttoggler:toggle',
		enabledByCss = false,
		cssEnabledProperty = 'togglerEnabled',
		contentAttribute = 'alertContent',
		contentIdAttribute = 'alertContentId',
		errorState = false,
		errorModifier = 'errored'
	}) {
		super({
			root: root,
			element: element,
			eventType: eventType,
			toggledClass: toggledClass,
			preventDefault: preventDefault,
			blurOnToggle: blurOnToggle,
			contextAttribute: contextAttribute,
			targetAction: targetAction,
			beforeToggleEvent: beforeToggleEvent,
			toggleEvent: toggleEvent,
			enabledByCss: enabledByCss,
			cssEnabledProperty: cssEnabledProperty,
			errorState: errorState,
		});
		this.element = element;
		this.defaults.contentAttribute = contentAttribute;
		this.defaults.contentIdAttribute = contentIdAttribute;
		this.errorModifier = errorModifier;
	}


	toggle(value = null) {
		const finalValue = (value === null ? !this.toggled : !!value);
		if (finalValue) {
			if (this.busy) {
				return Promise.resolve();
			}
			const data = this.dataAttr();
			const contentAttribute = data.get('contentAttribute');
			const contentIdAttribute = data.get('contentIdAttribute');
			const errorState = data.get('errorState');
			const content = data.has(contentIdAttribute) ?
				this.root.querySelector('#' + data.get(contentIdAttribute)).innerHTML || '' :
				data.get(contentAttribute, '')
			;
			const contextElement = this.contexts.getContext(this.contextName).getElement();
			const contentElement = contextElement.querySelector(this.dataSelector(contentAttribute));
			if (contentElement) {
				contentElement.innerHTML = content;
			}
			if (errorState) {
				this.classList(contextElement).toggle(this.errorModifier);
			}
		}
		return super.toggle(value);
	}

}

export default AlertToggler;
