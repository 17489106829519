import PageComponent from '../component/page-component';


class SlideshowNavigation extends PageComponent {
	constructor({root, element,	navigationAttribute = 'goTo', currentClass = 'current', handleSwipe = true}) {
		super({root: root, element: element});
		this.navigationAttribute = navigationAttribute;
		this.defaults.currentClass = currentClass;
		this.defaults.handleSwipe = handleSwipe;
	}


	prepare() {
		this.slideshowId = this.dataAttr().get('for', '');
		this.progressValue = 0;
		this.progressInterval = null;
		if (this.slideshowId !== '') {
			this.slideshow = this.components.queryComponent(this.root, this.dataSelector('id', this.slideshowId));
			if (this.slideshow) {
				this.listeners.focus = this.events.on(this.element, this.dataSelector(this.navigationAttribute), 'focusin', this.onStopAutoplay.bind(this));
				this.listeners.stopAutoplay = this.events.on(this.root, 'slideshow:stopAutoplay', this.onStopAutoplay.bind(this));
				this.progressIndicator = this.element.querySelector(this.dataSelector('progress'));
				this.startProgress();
				this.playPauseBtn = this.element.querySelector(this.dataSelector('playpause'));
				this.listeners.click = this.events.on(this.element, this.dataSelector('playpause'), 'click', this.onPlayPause.bind(this));
				this.listeners.click = this.events.on(this.element, this.dataSelector(this.navigationAttribute), 'click', this.onClick.bind(this));
				this.currentClass = this.dataAttr().get('currentClass');
				if (this.currentClass) {
					this.listeners.beforeSwitch = this.events.on(this.slideshow.getElement(), 'slideshow:beforeswitch', this.onBeforeSwitch.bind(this));
					const currentSlide = this.slideshow.getCurrentSlide();
					// this should be already done in the markup, this is just to cover corner cases
					if (currentSlide) {
						const index = currentSlide.getIndex();
						const triggers = this.element.querySelectorAll(this.dataSelector(this.navigationAttribute, index));
						for (const trigger of triggers) {
							this.classList(trigger).add(this.currentClass);
							trigger.setAttribute('aria-selected', 'true');
						}
					}
				}

				if (this.dataAttr().get('handleSwipe')) {
					this.listeners.swipe = this.events.on(this.slideshow.getElement(), 'swipe', this.onSwipe.bind(this));
				}
			}
		}
	}

	onStopAutoplay() {
		this.slideshow.pauseAutoplay();
		if (this.playPauseBtn) this.playPauseBtn.ariaLabel = 'Play';
		this.stopProgress();

	}
	onClick(event, target) {
		event.preventDefault();
		const index = this.dataAttr(target).get(this.navigationAttribute);
		this.slideshow.pauseAutoplay();
		switch (index) {
			case 'next':
				this.slideshow.next();
				break;
			case 'prev':
				this.slideshow.prev();
				break;
			default:
				this.slideshow.goTo(index);
		}
		if (this.playPauseBtn) this.playPauseBtn.ariaLabel = 'Play';
	}
	onPlayPause(event, target) {
		event.preventDefault();
		if (this.slideshow.isPlaying()) {
			this.slideshow.stop();
			if (this.playPauseBtn) this.playPauseBtn.ariaLabel = 'Pause';
			this.stopProgress();
		} else {
			this.slideshow.start();
			if (this.playPauseBtn) this.playPauseBtn.ariaLabel = 'Play';
			this.startProgress();
		}
	}

	onSwipe(event) {
		if (event.gesture.offsetDirection === this.events.gestures.DIRECTION_RIGHT) {
			this.slideshow.prev();
		} else {
			this.slideshow.next();
		}
		this.playPauseBtn.ariaLabel = 'Play';
	}


	onBeforeSwitch(event) {
		if (!event.defaultPrevented) {
			const detail = event.detail;
			const currentSlide = detail.currentSlide;
			const newSlide = detail.newSlide;
			this.startProgress();
			if (currentSlide) {
				const currentTriggers = this.element.querySelectorAll(this.dataSelector(this.navigationAttribute, currentSlide.getIndex()));
				for (const trigger of currentTriggers) {
					this.classList(trigger).remove(this.currentClass);
					trigger.setAttribute('aria-selected', 'false');
				}
			}
			if (newSlide) {
				const newTriggers = this.element.querySelectorAll(this.dataSelector(this.navigationAttribute, newSlide.getIndex()));
				for (const trigger of newTriggers) {
					this.classList(trigger).add(this.currentClass);
					trigger.setAttribute('aria-selected', 'true');
				}
			}
		}
	}
	stopProgress() {
		this.progressValue = 0;
		clearInterval(this.progressInterval);
		this.progress();
	}
	startProgress() {
		const duration = this.slideshow.getAutoplayer().duration;
		this.progressValue = 0;
		this.progress();
		clearInterval(this.progressInterval);
		this.progressInterval = setInterval(() => this.progress(), (duration*1000)/100);
	}
	progress() {
		if (this.progressIndicator) {
			this.progressIndicator.setAttribute('aria-valuenow', this.progressValue);
			this.progressIndicator.style.setProperty('--progress', this.progressValue);
			this.progressValue++;
		}
	}
}

export default SlideshowNavigation;
