import imagesLoaded from 'imagesloaded';
import {waitCondition} from '../../common/utils/wait';
import PageComponent from '../component/page-component';

class AsyncPicture extends PageComponent {
	constructor({root, element, loadedClass = 'loaded', autoload = true}) {
		super({root: root, element: element});
		this.defaults.autoload = autoload;
		this.defaults.loadedClass = loadedClass;
		this.loading = false;
		this.currentSize = null;
	}

	prepare() {
		this.openAsyncEvent('load');
		this.img = this.element;
		if (this.dataAttr().get('autoload') !== 'lazy') {
			this.load();
		}
	}

	load() {
		if (!this.loading) {
			this.loading = true;
			this.isPicture = this.element.tagName.toLowerCase() === 'picture';
			const nodes = this.isPicture ? [...this.element.parentNode.querySelectorAll('source'), this.element] : [this.element];
			if (this.isPicture) this.classList(this.element).add(this.dataAttr().get('loadedClass'));
			for (const node of nodes) {
				const dataAttr = this.dataAttr(node);

				this.setSize();
				this.listeners.resize = this.events.on(node, 'window:resize', this.setSize.bind(this));

				if (dataAttr.has('srcset')) {
					node.setAttribute('srcset', dataAttr.get('srcset'));
					dataAttr.remove('srcset');
				}
			}
			const condition = () => this.img.currentSrc && this.img.currentSrc.length;
			waitCondition(condition).then(() => {
				if (this.img.currentSrc.length > 0) {
					const img = new Image();
					img.src = this.img.currentSrc;
					imagesLoaded([img], (x) => {
						this.closeAsyncEvent('load');
						const loadedClass = this.dataAttr().get('loadedClass');
						this.classList(this.element).add(loadedClass);
						if (this.isPicture) {
							this.classList(this.element.parentNode).add(loadedClass);
						}
						this.events.trigger(this.element, 'picture:load', {component: this});
					});
				} else {
					this.closeAsyncEvent('load');
					const loadedClass = this.dataAttr().get('loadedClass');
					this.classList(this.element).add(loadedClass);
					if (this.isPicture) {
						this.classList(this.element.parentNode).add(loadedClass);
					}
					this.events.trigger(this.element, 'picture:load', {component: this});
				}
			});
		}
		return this.on('load');
	}

	getImg() {
		return this.img;
	}
	setSize() {
		if (this.currentSize !== this.img.offsetWidth && this.img.offsetWidth > 0) {
			this.currentSize = this.img.offsetWidth;
			this.img.setAttribute('sizes', this.img.offsetWidth + 'px');
		}
	}
}

export default AsyncPicture;
