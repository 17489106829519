import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/dist/ScrollToPlugin';
import domMixin from '../dom/dom-mixin';
import offsetTop from '../utils/offset-top';

gsap.registerPlugin(ScrollToPlugin);

class HashScroller extends domMixin() {

    constructor({events, duration = 2}) {
		super();
		this.events = events;
		this.duration = duration;
		this.events.on(document, 'history:hashchange', this.onHashChange.bind(this));
    }


	onHashChange(event) {
		const hash = event.detail.hash;
		//vb-23-10-2020: Added check for hash-length
		if (hash !== null && hash.length>0 && decodeURIComponent(hash) === hash && hash.indexOf('/')===-1) {
			let element = document.querySelector('#' + hash);
			if (!element) {
				element = document.querySelector(this.dataSelector('hash', hash));
			}
			if (element) {
				requestAnimationFrame(() => {
					const position = offsetTop(element);
					gsap.to(window, {duration: this.duration, scrollTo: {y: position, autoKill: false}});
				// }, 200);
				});
			}
		}
	}
}

export default HashScroller;
