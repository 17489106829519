import Context from '../../common/context/context';


class MenuContext extends Context {

	constructor({name, root, popOnKey = 'esc', fixedClass = 'fixed', activeClass = 'active', mainAttribute = 'main'}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.activeClass = activeClass;
		this.fixedClass = fixedClass;
		this.mainAttribute = mainAttribute;
	}


	beforeSwitchIn(otherContext) {
		return otherContext;
	}


	switchIn(otherContext) {
		const element = this.getElement();
		this.enableOutsideCloseTrigger();
		this.enableAnchorLinksHandling();
		element.setAttribute('aria-hidden', false);
		return this.threeStateTransition(element).add(this.activeClass);
	}


	switchOut(otherContext) {
		const element = this.getElement();
		element.setAttribute('aria-hidden', true);
		this.classList(element).add(this.fixedClass);
		this.disableOutsideCloseTrigger();
		this.disableAnchorLinksHandling();
		return this.threeStateTransition(element).remove(this.activeClass).then(() => {
			this.classList(element).remove(this.fixedClass);
			return otherContext;
		});
	}


	enableOutsideCloseTrigger() {
		const main = this.root.querySelector(this.dataSelector(this.mainAttribute));
		this.outsideClickListener = this.events.on(main, 'click', this.onOutsideClick.bind(this));
	}


	disableOutsideCloseTrigger() {
		if (this.outsideClickListener) {
			this.outsideClickListener.destroy();
		}
	}


	onOutsideClick(event) {
		if (this.contexts.getCurrentContext() === this) {
			this.contexts.pop();
		}
	}


	enableAnchorLinksHandling() {
		this.linkListener = this.events.on(this.getElement(), 'a[href^="#"]', 'click', this.onAnchorLinkClick.bind(this), {capture: true});
	}


	disableAnchorLinksHandling() {
		this.linkListener.destroy();
	}


	onAnchorLinkClick(event, target) {
		if (this.contexts.getCurrentContext() === this) {
			const id = target.getAttribute('href');
			if (!this.getElement().querySelector(id)) {
				event.preventDefault();
				this.contexts.pop().then(() => {
					target.click();
				});
			}
		}
	}

}


export default MenuContext;
