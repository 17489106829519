import {gsap} from 'gsap';
import PageComponent from '../../common/component/page-component';



class Indicator extends PageComponent {

	constructor({
		root,
		element,
		duration = 2,
		easing = 'Linear.easeNone',
		activeModifier = 'active',
		indicatorAttribute = 'indicator',
		cssIndicatorOffsetProperty = 'indicatorOffset',
		indicatorLayout = 'movingBar'
	}) {
		super({root: root, element: element});
		this.defaults.easing = easing;
		this.defaults.duration = duration;
		this.defaults.indicatorAttribute = indicatorAttribute;
		this.defaults.indicatorLayout = indicatorLayout;
		this.cssIndicatorOffsetProperty = cssIndicatorOffsetProperty;

		this.next = 0;
		this.selected = 0;
		this.progress = 0;
		this.previousProgress = 0;
		this.activeModifier = activeModifier;
		this.offset = 0;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		const indicatorAttribute = data.indicatorAttribute;
		this.startOffset = data.startOffset;
		this.duration = data.duration;
		this.easing = data.easing;
		this.for = data.for;
		this.indicatorLayout = data.indicatorLayout;
		this.offset = this.cssData().get(this.cssIndicatorOffsetProperty);

		this.indicatorPosition = 0;


		this.indicator = this.element.querySelector(this.dataSelector(indicatorAttribute));
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.getSpacings();
		this.createAnimation();
		this.setListeners();
	}


	createAnimation() {
		const fromX = this.indicatorLayout === 'movingBar' ? 0 : this.indicatorPosition + this.offset;
		const toX = this.indicatorLayout === 'movingBar' ? -this.indicatorPosition : 0;

		this.tween = gsap.fromTo(this.indicator, {
			duration: this.duration,
			x: fromX,
			ease: 'none',
		}, {
			x: toX,
			ease: 'none',
			paused: true
		});
	}



	setListeners() {
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.listeners.changing = this.events.on(document, 'indicator:change', this.onChange.bind(this));
	}


	getSpacings() {
		this.indicatorPosition = -this.indicator.getBoundingClientRect().width;
	}


	onChange(event) {
		const id = event.detail.id;

		if (id === this.for) {
			const progress = event.detail.progress;

			if (event.detail.animate) {
				gsap.to(this.tween, {duration: 0.5, progress: progress, ease: this.easing});
			} else {
				this.tween.progress(progress);
			}
		}
	}


	onResize() {
		this.getSpacings();
		this.createAnimation();
	}
}


export default Indicator;
