import PageComponent from '../component/page-component';
import {waitFrame} from '../utils/wait';


class Tabs extends PageComponent {

	constructor({
		root,
		element,
		eventType = 'click',
		tabAttribute = 'tab',
		wrapperAttribute = 'tabsWrapper',
		selectedClass = 'selected',
		preventDefault = true,
		blurOnToggle = true,
		beforeSwitchEvent = 'tabs:beforeswitch',
		switchEvent = 'tabs:switch',
		enabledByCss = false,
		cssEnabledProperty = 'tabsEnabled',
		selectContentParent = false,
	}) {
		super({root: root, element: element});
		this.defaults.eventType = eventType;
		this.defaults.tabAttribute = tabAttribute;
		this.defaults.wrapperAttribute = wrapperAttribute;
		this.defaults.selectedClass = selectedClass;
		this.defaults.preventDefault = preventDefault;
		this.defaults.blurOnToggle = blurOnToggle;
		this.defaults.beforeSwitchEvent = beforeSwitchEvent;
		this.defaults.switchEvent = switchEvent;
		this.defaults.enabledByCss = enabledByCss;
		this.defaults.cssEnabledProperty = cssEnabledProperty;
		this.defaults.selectContentParent = selectContentParent;
		this.currentTab = null;
		this.currentContent = null;
		this.wrapper = null;
		this.busy = false;
	}


	prepare() {
		const data = this.dataAttr().getAll();

		if (data.wrapperAttribute in data) {
			this.wrapper = this.element.querySelector(this.dataSelector(data[data.wrapperAttribute]));
		}
		if (!this.wrapper) {
			this.wrapper = this.element;
		}

		const tabSelector = this.dataSelector(data.tabAttribute);
		this.currentTab = this.element.querySelector(this.classSelector(data.selectedClass) + tabSelector);
		if (this.currentTab) {
			this.currentContent = this.element.querySelector(this.getContentSelectorByTab(this.currentTab));
		}

		this.listeners.select = this.events.on(this.element, tabSelector, data.eventType, this.onSelect.bind(this));
	}


	onSelect(event, target) {
		const data = this.dataAttr().getAll();
		if (data.preventDefault) {
			event.preventDefault();
		}
		if (data.blurOnToggle) {
			target.blur();
		}
		if (target !== this.currentTab && (!data.enabledByCss || this.cssData().get(data.cssEnabledProperty, true))) {
			this.selectByTab(target);
		}
	}


	getContentSelectorByTab(tab) {
		let selector = null;
		const tabData = this.dataAttr(tab);
		if (tabData.has('for')) {
			selector = this.dataSelector('id', tabData.get('for'));
		} else if (tab.hasAttribute('href')) {
			const href = tab.getAttribute('href');

			const regexMatchHash = /#.*/;
			const found = href.match(regexMatchHash);
			if (found) {
				selector = found[0];
			}
		}
		if (selector === null) {
			throw new Error('Missing reference to the tab, it requires data-for="tabId" or href="#tabId"');
		}
		return selector;
	}


	selectByTab(tab) {
		const content = this.element.querySelector(this.getContentSelectorByTab(tab));
		return this.select(tab, content);
	}


	select(newTab, newContent) {
		if (this.busy) {
			return Promise.resolve();
		}
		const data = this.dataAttr().getAll();
		this.busy = true;
		const beforeEvent = this.events.trigger(this.element, data.beforeSwitchEvent, {component: this, currentTab: this.currentTab, newTab: newTab, currentContent: this.currentContent, newContent: newContent});
		if(beforeEvent.defaultPrevented) {
			this.busy = false;
			return Promise.resolve();
		}
		const currentWrapperHeight = this.wrapper.getBoundingClientRect().height;
		const currentContentHeight = this.currentContent.getBoundingClientRect().height;
		const newContentHeight = newContent.getBoundingClientRect().height;
		const newWrapperHeight = currentWrapperHeight - currentContentHeight + newContentHeight;
		return waitFrame().then(() => {
			this.wrapper.style.height = currentWrapperHeight + 'px';
			return waitFrame();
		}).then(() => {
			this.wrapper.style.height = newWrapperHeight + 'px';
			this.classList(this.currentTab).remove(data.selectedClass);
			this.currentTab.setAttribute('aria-expanded', false);
			this.classList(newTab).add(data.selectedClass);
			newTab.setAttribute('aria-expanded', true);
			this.classList(data.selectContentParent ? this.currentContent.parentNode : this.currentContent).remove(data.selectedClass);
			this.classList(data.selectContentParent ? newContent.parentNode : newContent).add(data.selectedClass);
			return this.onTransitionEnd(this.wrapper);
		}).then(() => {
			this.currentTab = newTab;
			this.currentContent = newContent;
			this.wrapper.style.removeProperty('height');
			this.events.trigger(this.element, data.switchEvent, {component: this, currentTab: this.currentTab, currentContent: this.currentContent});
			this.currentContent.focus({preventScroll: true});
			this.busy = false;
		});
	}

	isBusy() {
		return this.busy;
	}

}

export default Tabs;
