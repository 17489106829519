import PageComponent from '../../common/component/page-component';


// the purpose of this component is to replicate the table headers inside each cell,
// in order to show a label > value structure on small screens where the table layout is switching to a linear block layout
class TableSearch extends PageComponent {

	constructor({
		root,
		element,
		searchInputAttribute = 'searchInput',
		tableRowAttribute = 'tableRow',
		cellTextAttribute = 'cellText',
		hiddenModifier = 'hidden'
	}) {
		super({root: root, element: element});
		this.searchInputAttribute = searchInputAttribute;
		this.tableRowAttribute = tableRowAttribute;
		this.cellTextAttribute = cellTextAttribute;
		this.hiddenModifier = hiddenModifier;
	}

	prepare() {
		this.searchInput = this.element.querySelector(this.dataSelector(this.searchInputAttribute));
		this.tableRowNodes = this.element.querySelectorAll(this.dataSelector(this.tableRowAttribute));

		this.listeners.change = this.events.on(this.searchInput, 'input', this.onChange.bind(this));
		this.listeners.key = this.events.on(this.searchInput, 'keypress', this.onKeypress.bind(this));
	}

	onChange(event) {
		const input = event.target.value;
		const noPunctationInput = input.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');
		const regex = new RegExp(noPunctationInput.toLowerCase());

		for (const row of this.tableRowNodes) {
			let foundCounter = 0;
			const cellTextNodes = row.querySelectorAll(this.dataSelector(this.cellTextAttribute));

			for (const cellTextNode of cellTextNodes) {
				const textValue = cellTextNode.innerHTML;
				const lowercaseTextValue = textValue.toLowerCase();
				const noPunctationValue= lowercaseTextValue.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');


				const found = noPunctationValue.match(regex);
				if (found) {
					foundCounter++;
				}
			}

			if (foundCounter > 0) {
				if (this.classList(row).contains(this.hiddenModifier)) {
					this.classList(row).remove(this.hiddenModifier);
				}
			} else {
				if (!this.classList(row).contains(this.hiddenModifier)) {
					this.classList(row).add(this.hiddenModifier);
				}
			}
		}
	}

	onKeypress(event) {
		const code = event.keyCode || event.which;
		if (code === 13) {
			event.preventDefault();
			return false;
		}
		return false;
	}

}


export default TableSearch;
