import {TweenMax} from 'gsap';
import {getScrollTop} from '../../common/utils/get-scroll';
import PageComponent from '../../common/component/page-component';


class CoverSection extends PageComponent {

	constructor({
		root,
		element,
		duration = 0,
		ratio = 0.3
	}) {
		super({root: root, element: element});
		this.defaults.duration = duration;
		this.defaults.ratio = ratio;

		this.height = 0;
		this.tween = null;
	}


	prepare() {
		const disabled = !!document.querySelector('html.iOs');
		if (!disabled) {
			const data = this.dataAttr().getAll();
			this.duration = data.duration;
			this.ratio = data.ratio;
			this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
			this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
			this.updateSize();
		}
	}


	clear() {
		if (this.tween) {
			this.tween.kill();
		}
	}


	onResize(event) {
		this.updateSize();
	}


	onScroll(event) {
		this.updatePosition();
	}


	updateSize() {
		this.height = this.element.getBoundingClientRect().height;
		this.updatePosition();
	}

	updatePosition() {
		const scrollTop = getScrollTop();
		if (scrollTop <= this.height) {
			TweenMax.killTweensOf(this.element);
			// this.tween = TweenMax.to(this.element, this.duration, {y: '-' + Math.round(scrollTop * this.ratio) + 'px', force3D: true});
			this.element.style.transform = 'translateY(-' + (scrollTop * this.ratio) + 'px) translateZ(0px)';
		}
	}

}


export default CoverSection;
